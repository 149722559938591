export default {
	init() {
		const $ = jQuery

		$(window).on('postAppended', function () {
			imgToSvg()
		})

		// Inline svg
		function imgToSvg() {
			$('img.inline-svg').each((img, e) => {
				const $img = $(e)
				const imgId = $img.attr('id')
				const imgClass = $img.attr('class')
				const imgUrl = $img.attr('src')
				$.get(
					imgUrl,
					(data) => {
						let $svg = $(data).find('svg')
						if (typeof $imgId !== 'undefined') {
							$svg = $svg.attr('id', imgId)
						}
						if (typeof $imgClass !== 'undefined') {
							$svg = $svg.attr('class', $imgClass)
						}
						$svg = $svg.removeAttr('xmlns:a')
						if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
							$svg.attr(`viewBox 0 0 ${$svg.attr('height')} ${$svg.attr('width')}`)
						}
						$img.replaceWith($svg)
					},
					'xml'
				)
			})
		}
		imgToSvg()

		// Prevent default hyperlink behavior if no URL
		$('a[href="#"]').on('click', function (e) {
			e.preventDefault()
		})

		// Retain iframe video aspect ratio
		const $videos = $(
			'iframe[src^="https://www.facebook.com"], iframe[src^="https://facebook.com"], iframe[src^="https://www.youtube.com"], iframe[src^="https://youtube.com"]'
		)
		$videos.each(function () {
			$(this)
				.data('aspectRatio', this.height / this.width)
				.removeAttr('height')
				.removeAttr('width')
		})
		$(window)
			.resize(function () {
				let newWidth = 0
				$videos.each(function () {
					let $vid = $(this)
					let container = $vid.parent()
					newWidth = container.width()
					$vid.width(newWidth).height(newWidth * $vid.data('aspectRatio'))
				})
			})
			.resize()
	},
	finalize() {
		const $ = jQuery

		// Topics trigger
		$('body').on('click', '.topics-trigger', function () {
			$('.site-header .header-topics').addClass('active')
			$('.site-header .header-editorials, .site-header .header-search .header-storytellers').removeClass('active')
			$('body').addClass('lock-scroll')
		})
		$('body').on('click', '.close-topics', function () {
			$('.site-header .header-topics').removeClass('active')
			$('body').removeClass('lock-scroll')
		})

		// Storytellers trigger
		$('body').on('click', '.storytellers-trigger', function () {
			$('.site-header .header-storytellers').addClass('active')
			$('.site-header .header-editorials, .site-header .header-search .header-topics').removeClass('active')
			$('body').addClass('lock-scroll')
		})
		$('body').on('click', '.close-storytellers', function () {
			$('.site-header .header-storytellers').removeClass('active')
			$('body').removeClass('lock-scroll')
		})

		// Editorials trigger
		$('body').on('click', '.editorials-trigger', function () {
			$('.site-header .header-editorials').addClass('active')
			$('.site-header .header-topics, .site-header .header-search .header-storytellers').removeClass('active')
			$('body').addClass('lock-scroll')
		})
		$('body').on('click', '.close-editorials', function () {
			$('.site-header .header-editorials').removeClass('active')
			$('body').removeClass('lock-scroll')
		})

		// Search trigger
		$('body').on('click', '.search-trigger', function () {
			$('.site-header .header-search').addClass('active')
			$('.site-header .header-topics, .site-header .header-editorials .header-storytellers').removeClass('active')
			$('body').addClass('lock-scroll')
			$('.site-header .header-search input').focus()
		})
		$('body').on('click', '.close-search', function () {
			$('.site-header .header-search').removeClass('active')
			$('body').removeClass('lock-scroll')
		})

		// Switch header

		$(window).on('scroll', function () {
			if ($(window).scrollTop() > 240) {
				$('.site-header .collapsed').addClass('active')
				$('.site-header').addClass('is-collapsed')
			} else {
				$('.site-header .collapsed').removeClass('active')
				$('.site-header').removeClass('is-collapsed')
			}
		})

		// Reset on resize

		$(window).on('resize', function () {
			if ($(window).width() >= 820) {
				$('.menu-item-has-children').removeClass('open').find('ul').hide()
			} else {
				$(
					'.site-header .header-topics, .site-header .header-editorials, .site-header .header-search .header-storytellers'
				).removeClass('active')
				$('body').removeClass('lock-scroll')
			}
		})

		// Article info in header

		let didScroll
		let lastScrollTop = 0
		const headerHeight = $('.site-header').height()
		$(window).on('scroll', function () {
			didScroll = true
		})

		setInterval(function () {
			if (didScroll) {
				hasScrolled()
				didScroll = false
			}
		}, 250)

		const hasScrolled = () => {
			let scrollY = $(window).scrollTop()
			if (Math.abs(lastScrollTop - scrollY) <= 5) return

			if (scrollY > lastScrollTop && scrollY > headerHeight) {
				$('.site-header').removeClass('scroll-down').addClass('scroll-up')
				$('.site-header .primary-menu').removeClass('open')
				$('.site-header .hamburger').removeClass('open')
			} else if (scrollY + $(window).height() < $(document).height()) {
				$('.site-header').removeClass('scroll-up').addClass('scroll-down')
			}
			lastScrollTop = scrollY
		}

		// Hamburger toggle
		$('body').on('click', '.site-header .hamburger', function () {
			$(this).toggleClass('open')
			$('.site-header .primary-menu').toggleClass('open')
		})

		// Mobile sub menu
		$('body').on('click', '.site-header .primary-menu li.menu-item-has-children button', function () {
			$(this).parents('li').toggleClass('open').find('ul').toggle()
		})

		// Load more
		$('.load-more-button').on('click', function () {
			const button = $(this)
			loadMorePosts(button)
		})

		// Load more on scroll
		if (!document.body.classList.contains('home') && document.querySelector('.load-more-button:not(.disable-load-more-on-scroll)')) {
			const loadMoreObserver = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio > 0) {
						const button = $('.load-more-button:not(.disable-load-more-on-scroll)')
						loadMorePosts(button)
					}
				})
			})
			loadMoreObserver.observe(document.querySelector('.load-more-button:not(.disable-load-more-on-scroll)'))
		}

		function loadMorePosts(button) {
			button.html('Loading...')
			const postsContainer = button.parent().siblings('.posts')
			const params = postsContainer.data('load-more')
			let noMasonry = false
			if (
				params.type === 'series-terms' ||
				params.type === 'search' ||
				params.type === 'post_format' ||
				params.type === 'video'
			) {
				noMasonry = true
			}
			const data = {
				type: params.type,
				term: params.term,
				card: params.card,
				offset: params.offset,
				posts_per_page: params.posts_per_page,
				exclude: params.exclude ? JSON.parse(params.exclude) : [],
				action: params.type === 'series-terms' ? 'LOAD_MORE_SERIES_TERMS' : 'LOAD_MORE_POSTS',
				security: globals.load_more_nonce
			}
			$.post(globals.ajaxUrl, data, function (response) {
				response = $(response)
				params.offset += params.posts_per_page
				postsContainer.data('load-more', params)
				if (!noMasonry) {
					postsContainer.append(response).masonry('appended', response)
				} else {
					postsContainer.append(response)
				}
				if (params.offset >= params.total) {
					button.fadeOut()
				}
				button.html('Load More')
				$(window).trigger('postsLoaded')
			})
		}

		// Newsletter subscription

		$('.subscription-form').on('submit', function (e) {
			e.preventDefault()
			let form = $(this)
			let email = form.find('input[type="email"]').val()
			let button = form.find('button')
			let buttonText = button.text()
			const data = {
				email: email,
				action: 'NEWSLETTER_SUBSCRIPTION',
				security: globals.newsletter_subscription_nonce
			}
			form.find('input[type="email"]').attr('style', '')
			form.siblings('.form-message').text('')
			button
				.addClass('loading-animation')
				.append('<div class="button-loader"><span>&bull;</span><span>&bull;</span><span>&bull;</span></button>')
			$.post(globals.ajaxUrl, data)
				.done(function (response) {
					if (response === 'success') {
						form.get(0).reset()
						form.siblings('.form-message').text('Thank you!')
					} else if (response === 'exists') {
						form.find('input[type="email"]').css('border', '3px inset #FC4844')
						form.siblings('.form-message').text('You are already subscribed!')
					} else {
						form.find('input[type="email"]').css('border', '3px inset #FC4844')
						form.siblings('.form-message').text('There was a problem, please try again.')
					}
				})
				.fail(function (xhr, status, error) {
					form.find('input[type="email"]').css('border', '3px inset #FC4844')
					form.siblings('.form-message').text('There was a problem, please try again.')
				})
				.always(function () {
					button.find('.button-loader').fadeOut(function () {
						$(this).remove()
					})
				})
		})

		// Share buttons

		$('body').on('click', '.share-buttons li', function () {
			let platform = $(this).data('share')
			let url = window.location.href
			let title = document.title
			if (platform === 'facebook') {
				window.open(
					`https://www.facebook.com/sharer/sharer.php?u=${url}`,
					'sharer',
					'toolbar=0,status=0,width=580,height=325'
				)
			} else if (platform === 'twitter') {
				window.open(
					`http://twitter.com/share?text=${title}&url=${url}`,
					'sharer',
					'toolbar=0,status=0,width=580,height=325'
				)
			}
		})

		// Lazy loading

		$(window).on('load scroll postsLoaded tabChanged', function () {
			;[...document.querySelectorAll('img[data-src]')].map((i) => imageIO.observe(i))
		})

		const imageIO = new IntersectionObserver((entries) =>
			entries.forEach((entry) => {
				if (entry.isIntersecting && !$(entry.target).attr('src')) {
					let img = $(entry.target)
					let src = img.data('src')
					img.attr('src', src)
					img.css('opacity', 1)
				}
			})
		)
		//javascript for stories
		function getCookie(cname) {
			let name = cname + "=";
			let decodedCookie = decodeURIComponent(document.cookie);
			let ca = decodedCookie.split(';');
			for(let i = 0; i <ca.length; i++) {
			  let c = ca[i];
			  while (c.charAt(0) == ' ') {
				c = c.substring(1);
			  }
			  if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			  }
			}
			return "";
		  }
		  function setCookie(cname, cvalue, exdays) {
			const d = new Date();
			d.setTime(d.getTime() + (exdays*24*60*60*1000));
			let expires = "expires="+ d.toUTCString();
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		  }
		  var homeadd = getCookie('homeadd');
		  if(homeadd == ''){
			  var vis1 = jQuery("header").attr("data-vis1");
		  if(vis1 == 'Active'){
			  var stdate = jQuery("header").attr("data-stdate");
		  var endate = jQuery("header").attr("data-endate");
		  var varDate = new Date(stdate); //dd-mm-YYYY
		  var varDateed = new Date(endate); //dd-mm-YYYY
		  var today = new Date();
		  if(today >= varDate && today < varDateed) {
				  setCookie('homeadd','enable',360);
		  
		  var lnks =  jQuery("header").attr("data-link");   
		  var ban_1 = jQuery("header").attr("data-banner");
		  var ban_2 = jQuery("header").attr("data-banner2");
		  jQuery(".featured-slider-glide .glide__bullets").append('<button type="button" aria-label="Slide 6" class="glide__bullet" data-glide-dir="=6"></button>');
		  jQuery(".featured-slider-glide .glide__slides").prepend("<article class='glide__slide post-64215 post type-post status-publish format-standard has-post-thumbnail hentry category-culture category-life category-lifestyle tag-debt tag-finance tag-furniture tag-home tag-home-ownership tag-singapore topic-jobs-economy topic-lifestyle series-coming-of-age'><a href='"+lnks+"'><img class='desk-img' src='"+ban_1+"'><img class='mb-img' src='"+ban_2+"'></a></article");
		  }
		  }
		  
		  } var stdate = jQuery("header").attr("data-artst");
		  var endate = jQuery("header").attr("data-arten");
		  var varDate = new Date(stdate); //dd-mm-YYYY
		  var varDateed = new Date(endate); //dd-mm-YYYY
		  var today = new Date();
		  var lnks =  jQuery("header").attr("data-artlnk");   
		  var ban_1 = jQuery("header").attr("data-art");var vis1 = jQuery("header").attr("data-vis1");
		  if(today >= varDate && today < varDateed) {
				  var vis1 = jQuery("header").attr("data-visb2");
			  if(vis1 == 'Active'){
			  jQuery(".home .tabbed-articles .article-cards article").eq(1).append("<div class='article-add'><a href='"+lnks+"'><img src='"+ban_1+"'></a></div>");
		  jQuery(".home .tabbed-articles .article-cards article").eq(1).find(".card--inner").hide();    
		  }
		}
	}
}
