import Glide from '@glidejs/glide'

export default {
	init() {
		const $ = jQuery

		const seriesSlider = document.querySelectorAll('.series-slider-glide')
		for (let i = 0; i < seriesSlider.length; i++) {
			const glide = new Glide(seriesSlider[i], {
				type: 'slider',
				perView: 4,
				perTouch: 1,
				gap: 52.5,
				peek: 175,
				rewind: true,
				bound: true,
				breakpoints: {
					2560: {
						gap: 37.5,
						peek: 125
					},
					1920: {
						gap: 30,
						peek: 100
					},
					1440: {
						perView: 3,
						peek: 75
					},
					1360: {
						perView: 3,
						peek: 16,
						gap: 16
					},
					768: {
						type: 'carousel',
						perView: 1,
						peek: 120,
						gap: 30
					},
					540: {
						perView: 1,
						gap: 0,
						peek: 0
					}
				}
			})
			glide.mount()
		}
	},
	finalize() {}
}
