/*
 * There are 4 types of payment methods here: card, Google Pay, GrabPay, PayNow.
 * Based on the selection of the contribution type (single/monthly/yearly) and
 * contribution amount (from radio boxes or custom input), the variables `contributionPeriod`
 * and `contributionAmount` are set.
 *
 * When a payment method type is selected (PayNow Button/GrabPay Button, Google Pay Button or
 * if the form is submitted using the `Confirm Payment` button for card payments), one of two
 * functions is called, based on contribution period selection: createSubscription or
 * createSinglePayment.
 */

const $ = jQuery
const stripe = Stripe(globals.stripe_pk)

export default {
	init() {},
	finalize() {
		let contributionAmount = parseInt(
			$('.contribution-amounts.active input[name="contribution-amount"]:checked').val()
		)
		let contributionPeriod = $('.contribution-periods input[name="contribution-period"]:checked').val()
		let paymentType = ''
		{
			/* Contribution period selection */
			$('.contribution-periods input[name="contribution-period"]').on('change', function (e) {
				const period = e.target.value
				contributionPeriod = period.toLowerCase()
				$(`.contribution-amounts[data-period="${period}"]`)
					.addClass('active')
					.siblings()
					.each(function () {
						$(this).removeClass('active')
					})
				// Remove checked state from all inputs
				$('.contribution-amounts input[type="radio"]:checked').each(function () {
					$(this).prop('checked', false)
				})
				// Add checked state to input with `data-checked` in active amounts group
				$('.contribution-amounts.active input[type="radio"][data-checked]').prop('checked', true)
				contributionAmount = parseInt($('.contribution-amounts.active input[type="radio"]:checked').val())
				// Hide GrabPay if monthly/annual is selected
				if (period !== 'single') {
					$('.contribution-grabpay-button').attr('disabled', true)
				} else {
					$('.contribution-grabpay-button').removeAttr('disabled')
				}
			})

			/* Remove active class from other selections if other field is focused */
			$('input[name="contribution-amount"]').on('focus', function () {
				$('.contribution-amounts.active input[type="radio"]').each(function () {
					$(this).prop('checked', false)
				})
			})

			/* Update contribution amount */
			$('.contribution-amounts input[name="contribution-amount"]').on('change', function (e) {
				const amount = parseInt(e.target.value)
				contributionAmount = amount
			})

			/* Mount Google Pay button */
			const paymentRequest = stripe.paymentRequest({
				country: 'SG',
				currency: 'sgd',
				total: {
					label: 'Contribution to RICE',
					amount: contributionAmount * 100,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			})

			const elements = stripe.elements()
			const googlePayButton = elements.create('paymentRequestButton', {
				paymentRequest,
			})

			paymentRequest.canMakePayment().then(result => {
				if (!result) {
					$('.contribution-google-pay-button').attr('disabled', true)
				}
			})

			$('.contribution-google-pay-button').on('click', () => {
				/* Update payment amount on amount selection  */
				paymentRequest.update({
					total: {
						label: `Contribution to RICE (${contributionPeriod})`,
						amount: contributionAmount * 100,
					},
				})
				paymentRequest.show()
			})

			/* Mount card payment fields */
			const cardNumber = elements.create('cardNumber')
			cardNumber.mount('.contribution-card-number')

			const cardExpiry = elements.create('cardExpiry')
			cardExpiry.mount('.contribution-card-expiry')

			const cardCvc = elements.create('cardCvc')
			cardCvc.mount('.contribution-card-cvc')

			/* When Google Pay flow is complete */
			paymentRequest.on('paymentmethod', function (e) {
				if (contributionPeriod !== 'single') {
					// Create subscription
					createSubscription(e.paymentMethod)
						.then(response => {
							if (response.success) {
								// Success message
								e.complete('success')
								showModal('success')
								clearCardElements(true)
							}
						})
						.catch(e => {
							// Error message
							e.complete('fail')
							showModal('error', e.error)
							clearCardElements(false)
						})
				} else {
					// Create one time payment
					createSinglePayment(e.paymentMethod)
						.then(response => {
							console.log(response)
							if (response.success) {
								// Success message
								e.complete('success')
								showModal('success')
								clearCardElements(true)
							}
						})
						.catch(e => {
							// Error message
							e.complete('fail')
							showModal('error', e.error)
							clearCardElements(false)
						})
				}
			})

			/* Card payment flow */

			$('.rice-contribution-app').on('submit', function (e) {
				e.preventDefault()
				submitContribution('card', cardNumber)
			})

			/* Create subscription */

			const createSubscription = paymentMethod =>
				new Promise((resolve, reject) => {
					const data = {
						action: 'CREATE_CONTRIBUTION_SUBSCRIPTION',
						security: globals.contribution_subscription_nonce,
						paymentMethod,
						amount: contributionAmount * 100,
						period: contributionPeriod,
					}
					$.post(globals.ajaxUrl, data, function (subscriptionResponse) {
						subscriptionResponse = JSON.parse(subscriptionResponse)
						const { clientSecret, status } = subscriptionResponse
						if (status === 'required_action') {
							if (paymentMethod.type === 'card') {
								stripe.confirmCardPayment(clientSecret).then(confirmCardPaymentResponse => {
									if (confirmCardPaymentResponse.error) {
										return reject({
											success: false,
											error: confirmCardPaymentResponse.error.message,
										})
									} else {
										return resolve({ success: true })
									}
								})
							} else if (paymentMethod.type === 'paynow') {
								createPayNowPayment().then(res => {
									if (res.paymentIntent.status === 'succeeded') {
										showModal('success')
										clearCardElements(true)
									} else {
										showModal('error', 'The payment did not go through. Please try again.')
										clearCardElements(false)
									}
								})
							}
						} else if (status === 'active') {
							// Success
							return resolve({ success: true })
						} else {
							return reject({ success: false, error: subscriptionResponse.message })
						}
					})
				})

			/* Create single payment */

			const createSinglePayment = paymentMethod =>
				new Promise((resolve, reject) => {
					const data = {
						action: 'CREATE_CONTRIBUTION_PAYMENT',
						security: globals.contribution_payment_nonce,
						amount: contributionAmount * 100,
						period: contributionPeriod,
						email: $('input[name="contributor-email"]').val(),
						paymentMethod: paymentMethod,
					}
					$.post(globals.ajaxUrl, data, paymentResponse => {
						const { client_secret: clientSecret, status } = JSON.parse(paymentResponse)
						if (paymentMethod.type === 'card') {
							stripe
								.confirmCardPayment(
									clientSecret,
									{
										payment_method: paymentMethod.id,
									},
									{ handleActions: false }
								)
								.then(confirmResult => {
									if (confirmResult.error) {
										return reject({ success: false, error: confirmResult.error.message })
									} else if (confirmResult.paymentIntent.status === 'requires_action') {
										stripe.confirmCardPayment(clientSecret).then(confirmCardPaymentResponse => {
											if (confirmCardPaymentResponse.error) {
												return reject({
													success: false,
													error: confirmCardPaymentResponse.error.message,
												})
											} else {
												return resolve({ success: true })
											}
										})
									} else if (confirmResult.paymentIntent.status === 'succeeded') {
										// Success
										return resolve({ success: true })
									} else {
										return reject({ success: false, error: confirmResult.error.message })
									}
								})
						} else if (paymentMethod.type === 'paynow') {
							console.log(createPayNowPayment instanceof Promise)
							createPayNowPayment().then(res => {
								if (res.paymentIntent.status === 'succeeded') {
									showModal('success')
									clearCardElements(true)
								} else {
									showModal('error', 'The payment did not go through. Please try again.')
									clearCardElements(false)
								}
							})
						}
					})
				})

			/* Create GrabPay payment */

			const createGrabPayPayment = () => {
				new Promise((resolve, reject) => {
					const data = {
						action: 'CREATE_GRABPAY_PAYMENT',
						security: globals.contribution_payment_nonce,
						amount: contributionAmount * 100,
						period: contributionPeriod,
						email: $('input[name="contributor-email"]').val(),
					}
					$.post(globals.ajaxUrl, data, intent => {
						const { client_secret: clientSecret, status } = JSON.parse(intent)
						stripe.confirmGrabPayPayment(clientSecret, {
							return_url: `https://www.ricemedia.co/pledge/pledge?payment_type=grabpay&amount=${
								data.amount / 100
							}&period=${data.period}`,
						})
					})
				})
			}
			$('.contribution-grabpay-button').on('click', () => {
				paymentType = 'grabpay'
				if ($('.rice-contribution-app').get(0).reportValidity()) {
					createGrabPayPayment()
				}
			})

			$(window).on('load', () => {
				const urlParams = new URLSearchParams(window.location.search)
				if (urlParams.get('payment_type') === 'grabpay') {
					if (urlParams.get('redirect_status') === 'succeeded') {
						showModal('success', null, urlParams.get('amount'), urlParams.get('period'))
					} else {
						showModal('error')
					}
				}
			})

			/* Create PayNow payment */

			const createPayNowPayment = () => {
				return new Promise((resolve, reject) => {
					const data = {
						action: 'CREATE_PAYNOW_PAYMENT',
						security: globals.contribution_payment_nonce,
						amount: contributionAmount * 100,
						period: contributionPeriod,
						email: $('input[name="contributor-email"]').val(),
					}
					$.post(globals.ajaxUrl, data, intent => {
						const { client_secret: clientSecret, status } = JSON.parse(intent)
						stripe.confirmPayNowPayment(clientSecret).then(res => {
							if (res.paymentIntent.status === 'succeeded') {
								resolve(res)
							} else {
								reject(res.error)
							}
						})
					})
				})
			}

			/* Create payment method */

			const createPaymentMethod = (type, cardNumber = null) => {
				const args = {
					type,
					billing_details: {
						name: `${$('input[name="contributor-fname"]').val()} ${$(
							'input[name="contributor-lname"]'
						).val()}`,
						email: $('input[name="contributor-email"]').val(),
					},
				}
				if (cardNumber) {
					args.card = cardNumber
				}
				return stripe.createPaymentMethod(args)
			}

			/* Submit contribution: subscription and single payments */

			const submitContribution = (type, cardNumber = null) => {
				const form = $('.rice-contribution-app')
				form.find('button[type="submit"]').text('Processing...')

				createPaymentMethod(type, cardNumber).then(paymentMethodResponse => {
					if (paymentMethodResponse.error) {
						console.error(paymentMethodResponse.error.message)
						showModal('error', paymentMethodResponse.error.message)
					} else {
						if (contributionPeriod !== 'single') {
							// Create subscription
							createSubscription(paymentMethodResponse.paymentMethod)
								.then(response => {
									if (response.success) {
										// Success message
										showModal('success')
										clearCardElements(true)
									}
								})
								.catch(e => {
									// Error message
									showModal('error', e.error)
									clearCardElements(false)
								})
								.finally(() => {
									form.find('button[type="submit"]').text('Confirm Payment')
								})
						} else {
							// Create single payment
							createSinglePayment(paymentMethodResponse.paymentMethod)
								.then(response => {
									// Success message
									console.log(response)
									showModal('success')
									clearCardElements(true)
								})
								.catch(e => {
									// Error message
									showModal('error', e.error)
									clearCardElements(false)
								})
								.finally(() => {
									form.find('button[type="submit"]').text('Confirm Payment')
								})
						}
					}
				})
			}

			/* PayNow button on click */

			$('.contribution-paynow-button').on('click', () => {
				paymentType = 'paynow'
				if ($('.rice-contribution-app').get(0).reportValidity()) {
					submitContribution('paynow')
				}
			})

			/* Show credit card form */

			$('.show-contribution-card-button, .contribution-card-button').on('click', function () {
				if ($(this).is('button')) {
					$('.show-contribution-card-button').prop('checked', true)
				}
				$('.rice-contribution-app').removeClass('hide-card-details')
			})

			/* Show success/error modal */

			const showModal = (status, message, amount = null, period = null) => {
				const modal = $('.contribution-modal')
				if (status === 'success') {
					modal.find('h2').text('Thank you for your contribution!')
					if (paymentType === 'paynow' && contributionPeriod !== 'single') {
						modal
							.find('p')
							.text(
								`Your ${contributionPeriod} contribution of $${contributionAmount} has been received. Please check your email and pay the invoice to start the ${contributionPeriod} contribution.`
							)
					} else {
						modal
							.find('p')
							.text(
								`Your ${
									period !== 'single' && contributionPeriod !== 'single'
										? period ?? contributionPeriod + ' '
										: ''
								}contribution of $${
									amount ?? contributionAmount
								} has been received and will be processed shortly.`
							)
					}
				} else {
					modal.find('h2').text('Sorry, your contribution could not be processed.')
					modal.find('p').text(`${message} Please try again.`)
				}
				$('body').addClass('lock-scroll')
				modal.addClass('active')
			}

			/* Close modal */

			$('.contribution-modal button').click(function () {
				$('.contribution-modal').removeClass('active')
				$('body').removeClass('lock-scroll')
			})

			/* Clear card elements */
			const clearCardElements = clearForm => {
				cardNumber.clear()
				cardExpiry.clear()
				cardCvc.clear()
				if (clearForm) {
					$('.rice-contribution-app').trigger('reset')
				}
			}
		}
	},
}
