export default {
	init() {
		const $ = jQuery

		// Load next article when scrolled halfway through current page
		let hasScrolledPast = false
		let scrollDistance = 0
		let aggScroll = 0

		$(window).on('scroll', function () {
			// Article page infinite scroll
			if (
				hasScrolledPast ||
				$('body').hasClass('single-format-audio') ||
				$('body').hasClass('single-format-video') ||
				$('body').hasClass('single-podcast') ||
				$('body main').hasClass('no-infinite-scrolling')
			)
				return false

			let totalHeight = $('main > article').last().height()
			scrollDistance = $(window).scrollTop()
			if ($('main > article').length > 1) {
				$('main > article').each(function (index, value) {
					if (index !== $('main > article').length - 1) {
						scrollDistance -= $(this).height()
					}
				})
			}

			if (scrollDistance > totalHeight / 2) {
				hasScrolledPast = true

				const article = $('main > article').last()
				const parent = article.parents('main')
				const postId = article.data('id')
				const format = article.data('format')
				const loadingAnimation = article.find('.article-loading-animation')

				loadingAnimation.css({
					visibility: 'visible',
					opacity: 1
				})

				const data = {
					post: postId,
					format: format,
					action: 'SINGLE_INFINITE_SCROLL',
					security: globals.single_infinite_scroll_nonce
				}

				$.ajaxSetup({
					cache: true
				})

				$.ajax({
					url: globals.ajaxUrl,
					data: data,
					cache: true,
					success: (response) => {
						parent.append($(response))
						loadingAnimation.attr('style', '')
						setTimeout(function () {
							loadingAnimation.hide()
						}, 300)
						$(window).trigger('postAppended')
						hasScrolledPast = false
					}
				})
			}

			// Observe article for changing title, url, thumbnail
			;[...document.querySelectorAll('main > article')].map((a) => io.observe(a))

			// Progress bar
			aggScroll = $(window).scrollTop()
			if ($(window).scrollTop() > $('main > .article-is-visible').prevAll('article').height()) {
				$('main > .article-is-visible')
					.prevAll('article:not(.article-is-visible)')
					.each(function () {
						aggScroll = aggScroll - $(this).height()
					})
			}
			let articleHeight = $('.article-is-visible').height()
			let scrollPercentage = (aggScroll / articleHeight) * 100
			$('.reading-progress .progress-bar .bar').css('width', `${scrollPercentage}%`)
		})

		$(window).on('postChanged', function () {
			$('.reading-progress .progress-bar .bar').css('width', '0%')
		})

		// Change title and url depending on scroll position

		const io = new IntersectionObserver((entries) =>
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					// Update title, url, thumbnail
					$(entry.target).addClass('article-is-visible')
					const postId = $(entry.target).data('id')

					const data = {
						post: postId,
						action: 'GET_POST_INFO',
						security: globals.single_infinite_scroll_nonce
					}

					$.post(globals.ajaxUrl, data, function (response) {
						response = JSON.parse(response)
						window.history.pushState({}, response.title, response.permalink)
						document.title = response.title
						$('.reading-progress .article-info-title').html(response.title)
						$('.reading-progress .article-thumbnail img').attr('src', response.thumbnail)
						$('.reading-progress .article-thumbnail img').attr('alt', response.title)
						$(window).trigger('postChanged')
					})
				} else if (!entry.isIntersecting && $(entry.target).siblings().size()) {
					$(entry.target).removeClass('article-is-visible')
				}
			})
		)

		// Open links in new tab

		$(window).on('load postChanged', function () {
			$('.article-content a').attr('target', '_blank')
		})

		// Image lightbox

		if (
			document.body.classList.contains('single-format-image') ||
			document.body.classList.contains('single-format-standard')
		) {
			let currentLightboxImage = null
			let allFigures = []
			const lightbox = $('main .photo-lightbox')
			const nav = lightbox.find('.lightbox-nav')

			$('body').on('click', '.article-content figure:not(:has(figure)):has(img)', function (e) {
				e.stopPropagation()
				lightbox.addClass('active')
				$('body').addClass('lock-scroll')
				const clickedFigure = $(this)
				const article = clickedFigure.parents('article')
				allFigures = [...article.find('figure:not(:has(figure)):has(img)')]
				// Add clicked image to lightbox
				currentLightboxImage = clickedFigure
				lightbox.find('.lightbox-image').html(clickedFigure.clone())
				lightbox.trigger('currentLightboxImage', [allFigures.indexOf(currentLightboxImage.get(0))])
				// Add other images to lightbox nav
				allFigures.forEach(function (element, index) {
					const navFig = $(element)
					nav.append(
						`<div data-id="${index}" class="lightbox-nav-image${
							currentLightboxImage.get(0) === navFig.get(0) ? ' active' : ''
						}">${navFig[0].outerHTML}</div>`
					)
				})
				// scroll to active nav item if needed
				let navItemOffset = $(
					`.lightbox-nav-image[data-id="${allFigures.indexOf(currentLightboxImage.get(0))}"]`
				)
					.get(0)
					.scrollIntoView()
			})

			$('body').on('click', '.close-lightbox', function () {
				$('.photo-lightbox').removeClass('active')
				$('body').removeClass('lock-scroll')
				$('.photo-lightbox .lightbox-image, .photo-lightbox .lightbox-nav').html('')
			})

			$('body').on('click', '.photo-lightbox .lightbox-nav-image', function () {
				$(this).addClass('active').siblings('.lightbox-nav-image').removeClass('active')
				const navFig = $(this).find('figure')
				currentLightboxImage = navFig
				lightbox.find('.lightbox-image').html(navFig.clone())
			})

			$('body').on('click', '.photo-lightbox .lightbox-button', function () {
				const direction = $(this).data('direction')
				let currentIndex

				allFigures.forEach(function (el, index) {
					if (el.outerHTML === currentLightboxImage.get(0).outerHTML) currentIndex = index
				})

				let index = direction === 'prev' ? currentIndex - 1 : currentIndex + 1
				if (currentIndex === 0 && direction === 'prev') {
					index = allFigures.length - 1
				}
				if (currentIndex === allFigures.length - 1 && direction === 'next') {
					index = 0
				}
				currentLightboxImage = $(allFigures[index])
				lightbox.find('.lightbox-image').html(currentLightboxImage.clone())

				nav.find('.lightbox-nav-image').each(function () {
					let navItem = $(this)
					let fig = navItem.find('figure')
					if (fig.get(0).outerHTML === currentLightboxImage.get(0).outerHTML) {
						navItem.addClass('active').siblings('.active').removeClass('active')
					}
				})
			})
		}
	},
	finalize() {}
}
