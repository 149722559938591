import Router from './utils/Router'
import common from './common'
import home from './home'
import single from './single'
import singleFormatVideo from './singleFormatVideo'
import pagePhoto from './pagePhoto'
import pageVideo from './pageVideo'
import pageEditorials from './pageEditorials'
import pageStorytellers from './pageStorytellers'
import pagePledge from './pagePledge'
import categoryVideoStorytellers from './categoryVideoStorytellers'
import taxonomy from './taxonomy'

const routes = new Router({
	common,
	home,
	single,
	singleFormatVideo,
	taxonomy,
	pagePhoto,
	pageVideo,
	pageEditorials,
	pageStorytellers,
	pagePledge,
	categoryVideoStorytellers
})

;(function ($) {
	routes.loadEvents()
})(jQuery)
