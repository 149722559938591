import Glide from '@glidejs/glide'

export default {
	init() {
		const $ = jQuery

		// Featured slider
		const featuredSlider = document.querySelectorAll('.featured-slider-glide')
		for (let i = 0; i < featuredSlider.length; i++) {
			const glide = new Glide(featuredSlider[i], {
				type: 'slider',
				gap: 52.5,
				peek: 175,
				breakpoints: {
					2560: {
						gap: 37.5,
						peek: 125
					},
					1920: {
						gap: 30,
						peek: 100
					},
					1440: {
						peek: 75
					},
					1360: {
						peek: 16,
						gap: 16
					},
					768: {
						gap: 15,
						peek: 45
					},
					480: {
						gap: 0,
						peek: 0
					}
				}
			})
			glide.mount()
		}

		// Series slider
		const seriesSlider = document.querySelectorAll('.series-slider-glide')
		for (let i = 0; i < seriesSlider.length; i++) {
			const glide = new Glide(seriesSlider[i], {
				type: 'slider',
				perView: 4,
				perTouch: 1,
				gap: 52.5,
				peek: 175,
				rewind: true,
				bound: true,
				breakpoints: {
					2560: {
						gap: 37.5,
						peek: 125
					},
					1920: {
						gap: 30,
						peek: 100
					},
					1440: {
						perView: 3,
						peek: 75
					},
					1360: {
						perView: 3,
						peek: 16,
						gap: 16
					},
					768: {
						type: 'carousel',
						perView: 1,
						peek: 120,
						gap: 30
					},
					540: {
						perView: 1,
						gap: 0,
						peek: 0
					}
				}
			})
			glide.mount()
		}

		// Trending slider
		const trendingSlider = document.querySelectorAll('.trending-slider-glide')
		for (let i = 0; i < trendingSlider.length; i++) {
			const glide = new Glide(trendingSlider[i], {
				type: 'carousel',
				perView: 1,
				perTouch: 1
			})
			glide.mount()
		}

		// Photo stories slider
		const photoSlider = document.querySelectorAll('.photo-stories-slider-glide')
		for (let i = 0; i < photoSlider.length; i++) {
			const glide = new Glide(photoSlider[i], {
				type: 'carousel',
				gap: 100,
				peek: 600,
				breakpoints: {
					2200: {
						peek: 400,
						gap: 100
					},
					1919: {
						peek: 296,
						gap: 100
					},
					1440: {
						peek: 360,
						gap: 60
					},
					1200: {
						peek: 100,
						gap: 30
					},
					768: {
						peek: 50,
						gap: 25
					},
					480: {
						peek: 30,
						gap: 15
					}
				}
			})
			glide.mount()
		}

		// Tabbed articles
		const tabMenu = $('.tab-menu')
		tabMenu.find('button').on('click', function () {
			const button = $(this)
			const tab = button.data('tab')
			const contentArea = tabMenu.siblings('.tab-content')
			const spinner = $('#spinner')
			const loadMoreButton = contentArea.find('.load-more-button')
			const cards = contentArea.find('.article-cards')
			const params = cards.data('load-more')
			const initialOffset = 5
			contentArea.css('opacity', 0)
			const data = {
				slug: tab,
				action: 'GET_TAB_ARTICLES',
				security: globals.tab_articles_nonce
			}
			tabMenu.find('button').each(function () {
				$(this).removeClass('active')
			})
			button.addClass('active')
			spinner.fadeIn()

			$.post(globals.ajaxUrl, data, function (response) {
				setTimeout(function () {
					spinner.fadeOut()
					cards.html(response).masonry('reload')
					let termTitle = cards.find('#tab-title').val()
					let termDescription = cards.find('#tab-description').val()
					let termTotal = parseInt(cards.find('#tab-total').val())

					contentArea.find('.section-title').html(termTitle)
					contentArea.find('.section-subtitle').html(termDescription)

					//Adding the culture tab that is category instead of topic
					//params.type = tab === 'recent' ? 'recent' : 'topic'
					if (tab === 'recent') {
						params.term = 'recent'
					} else {
						if (tab === 'culture') {
							params.term = 'category'
						}
						params.term = 'topic'
					}
					//params.term = tab === 'recent' ? '' : tab
					params.offset = initialOffset
					params.total = termTotal
					cards.data('load-more', params)
					contentArea.css('opacity', 1)

					if (params.offset <= params.total) {
						loadMoreButton.fadeIn()
					} else {
						loadMoreButton.fadeOut()
					}
					$(window).trigger('tabChanged')
				}, 300)
			})
		})
	},
	finalize() {}
}
