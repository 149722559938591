const $ = jQuery

export default {
	init() {},
	finalize() {
		// Impact counters
		const counterObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (entry.intersectionRatio > 0) {
					$('.page-storytellers__impact-counters .counter').each(function () {
						const counter = $(this)
						const numberElement = counter.find('.counter__number')
						const number = numberElement.data('number')
						animateNumber(numberElement.get(0), 0, number, 2500)
					})
				}
			})
		})
		counterObserver.observe(document.querySelector('.page-storytellers__impact-counters'))
	}
}

const animateNumber = (element, start, end, duration) => {
	let startTimestamp = null
	const step = (timestamp) => {
		if (!startTimestamp) startTimestamp = timestamp
		const progress = Math.min((timestamp - startTimestamp) / duration, 1)
		element.innerHTML = Math.floor(progress * (end - start) + start).toLocaleString('en')
		if (progress < 1) {
			window.requestAnimationFrame(step)
		}
	}
	window.requestAnimationFrame(step)
}
