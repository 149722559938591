import Glide from '@glidejs/glide'

export default {
	init() {
		const $ = jQuery

		// Photo stories slider
		const photoSlider = document.querySelectorAll('.photo-stories-slider-glide')
		for (let i = 0; i < photoSlider.length; i++) {
			const glide = new Glide(photoSlider[i], {
				type: 'carousel',
				gap: 100,
				peek: 600,
				breakpoints: {
					2200: {
						peek: 400,
						gap: 100
					},
					1919: {
						peek: 296,
						gap: 100
					},
					1440: {
						peek: 360,
						gap: 60
					},
					1200: {
						peek: 100,
						gap: 30
					},
					768: {
						peek: 50,
						gap: 25
					},
					480: {
						peek: 30,
						gap: 15
					}
				}
			})
			glide.mount()
		}
	},
	finalize() {}
}
