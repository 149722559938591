import Glide from '@glidejs/glide'

export default {
	init() {
		const $ = jQuery

		// Featured slider
		const featuredSlider = document.querySelectorAll('.featured-slider-glide')
		for (let i = 0; i < featuredSlider.length; i++) {
			const glide = new Glide(featuredSlider[i], {
				type: 'slider',
				gap: 37.5,
				peek: 125,
				breakpoints: {
					1920: {
						gap: 30,
						peek: 100
					},
					1440: {
						peek: 75
					},
					1360: {
						peek: 16,
						gap: 16
					},
					768: {
						gap: 15,
						peek: 45
					},
					480: {
						gap: 0,
						peek: 0
					}
				}
			})
			glide.mount()
		}
	},
	finalize() {}
}
